
<template>
    <div class="bird-card" :style="{ backgroundImage: `url(${backgroundImage})`}">
      <div class="name-badge" :style="{'font-size': getSize }">{{ name }}</div>
    </div>
</template>
  
<script>
  export default {
    name: 'BirdCard',
    props: {
      name: {
        type: String,
        required: true
      },
      backgroundImage: {
        type: String,
        required: false
      },
    
    },
    computed:{
      mode(){
        return this.$store.state.mode;
      },
      getSize(){
        if(this.mode === "english"){
          return "0.5em"
        }
        return "1em"
      }
    }
  }
  </script>
  
  <style scoped>
  .bird-card {
    width: 12vw; /* 调整为适当的宽度 */
    height: 12vw; /* 调整为适当的高度 */
    background-size: contain;
    background-position: center;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    background-repeat: no-repeat;
    max-width: 250px;
    min-width: 150px;
    max-height: 250px;
    min-height: 150px;
    /* background-color: rgba(255,255,255,0.5); */
    border-radius: 5%;
    background-color: rgba(255,255,255,0.5);
  }
  
  .name-badge {
    background: rgba(0, 255, 255, 0.7); /* 背景颜色和透明度 */
    border-radius: 5px;
    padding: 0.2em 0.4em;
    margin: 1em;
    font-weight: bold;
    color: black;
    font-size: 0.5em;
  }
  </style>

