<template>
    <div id="app" v-if="!loading">
        <div id="left-bar">
            <h2>{{ museums[idx].title}}</h2>
            <p>
                {{ museums[idx].paragraph}}
            </p>
            <img :key="idx2" v-for="(imgName,idx2) in museums[idx].pics" :src="require('@/assets/museums/'+imgName+'')" alt="">
        </div>
        <div id="right-bar">
            <div class="box" v-for="(box, index) in museums[idx].rightBars" :key="index">
                <h3>{{box.title}}</h3>
                <p>{{ box.p }}</p>
                <img :key="idx2" v-for="(imgName,idx2) in box.pics" :src="require('@/assets/museums/'+imgName+'')" alt="" :class="box.l == 1 ? 'img2':'img1'">
            </div>  
        </div>  
    </div>
</template>

<script>
import axios from 'axios'
export default{
    props:{
        idx:{
            type: Number,
            required: true,
        }
    },
    data(){
        return{
            museums:[],
            museumsB: [],
            loading: true,
        }
    },
    methods:{
        async get_data(){
            try {
                const res = await axios.post('https://api.symbiosisbirdweb.com/get-museum', {
            });
                if(this.mode === "chinese"){
                    this.museums = res.data
                }else{
                    this.museumsB = res.data
                }
                
            } catch (error) {
                console.error('There was an error!', error);
            }
            try {
                const res1 = await axios.post('https://api.symbiosisbirdweb.com/get-museumENG', {
            });
                if(this.mode === "chinese"){
                    this.museumsB = res1.data
                }else{
                    this.museums = res1.data
                }
                
                this.loading = false
            } catch (error) {
                console.error('There was an error!', error);
            }
        },
    },
    mounted(){
        this.get_data();
    },
    computed:{
        mode(){
            return this.$store.state.mode
        }
    },
    watch:{
        mode(){   
            let temp = this.museums
            this.museums = this.museumsB
            this.museumsB = temp
        }
    }
}

</script>


<style scoped>
    #left-bar h2{
        font-size:2vw;
        margin-top: 0px;
    }
    #left-bar p,#right-bar p{
        font-size: 1vw;
    }
    
    #app{
            /* min-width: 644px;
            min-height: 483px; */
            width: 62.25vw;
            height: 44vw;
            border-radius: 20px;
            margin: auto;
            display: flex;
            justify-content: space-between;
            background-color: white;
        }
        #left-bar{
            width: 50%;
            margin: 1.2vw;
            padding: 2.4vw;
            border-radius: 1.2vw;
            background-color: aliceblue;
            overflow: scroll;
        }
        #left-bar img{
            margin-top: 10px;
            width: 27vw;
            height: 27vw;
            border-radius: 20px;
            object-fit: cover;
        }
        #right-bar{
            overflow: scroll;
            width: 50%;
        }
        #right-bar .box{
            padding: 1.2vw;
            margin: 1.2vw;
            border-radius: 1vw;
            background-color: aliceblue;
        }
        #right-bar .box .img1{
            width: 10vw;
            height: 10vw;
            margin-left: 0.5vw;
            margin-right: 0.5vw;
            border-radius: 0.5vw;
            object-fit: cover;
        }
        #right-bar .box h3{
            font-size: 1.5vw;
            margin-top: 0.5vw;
        }
        #right-bar .box .img2{
            width: 22vw;
            height: 22vw;
            margin-left: 0.5vw;
            margin-right: 0.5vw;
            border-radius: 0.5vw;
            object-fit: cover;
            margin-top: 1vw;
            margin-bottom : 1vw;
        }
</style>

