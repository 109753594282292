<template>
    <div id="app">
      <div v-if="showModal" class="modal">
        <div class="modal-content" :style="{'width':width}">
          <span class="close" @click="changeSM">&times;</span>
          <slot name="content"></slot>
        </div>
      </div>
    </div>
</template>
  
  <script>
  export default {
    props:{
        showModal:{
            type:Boolean,
            required: true
        },
        idx: {
            type: Number,
            required: false,
        },
        width:{
            type: String,
            required: false,
        }
    },
    methods:{
        changeSM(){
            this.$emit('deleteSM',this.idx)
        }
    }
  };
  </script>
  
  <style scoped>
  #app {
    text-align: center;
  }
  
  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    border: 1px solid #888;
    width: 80%;
    max-width: 800px;
    max-height: 520px;
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    padding-bottom: 20px;
    
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 20px;
    color: #aaa;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  </style>
  