<template>
    <div id="app1" v-if="!loading">
        <ul>
            <li v-for="(bird,index) in birds" :key="index">
                <popup-window :showModal="visible[index]" :idx = "index" @deleteSM = "deleteModal">
                    <template #content>
                        <h2>{{bird.name}}</h2>
                        <div id="popUp">
                            <img :src="bird.imgPosition" alt="" id="windowIMG" :style="{width:bird.popup + '%'}">
                            <div id="right-bar">
                                <p>{{bird.description}}</p>
                            </div>  
                        </div>
                    </template>
                </popup-window>
                <bird-card :name="bird.name" :backgroundImage="bird.imgPosition" @click="changeShowModal(index)"></bird-card>
            </li>
        </ul>
    </div>
</template>

<script>
import BirdCard from './singleBird.vue'
import axios from 'axios'
import popupWindow from './popupWindow.vue';

export default{
    components:{
        BirdCard,
        popupWindow
    },
    data(){
        return{
            total_num: 1,
            birds: [],
            birdsENG: [],
            loading: true,
            visible: [],
            hasWindow: false
        }
    },
    methods:{
        async get_data(){
            try {
                const res = await axios.post('https://api.symbiosisbirdweb.com/get-data', {
            });
                for(let i =0 ;i<res.data.length;i++){
                    const temp = {
                        name: res.data[i].name,
                        imgPosition: require('@/assets/birds/'+res.data[i].img+''),
                        credit : res.data[i].credit,
                        popup : res.data[i].popup,
                        description: res.data[i].description
                    }
                    this.birds.push(temp)
                }
                for(let i = 0; i<res.data.length; i++){
                    this.visible.push(false)
                }
                
            } catch (error) {
                console.error('There was an error!', error);
                console.log("111")
            }
            try {
                const res1 = await axios.post('https://api.symbiosisbirdweb.com/get-dataENG', {
            });
                for(let i =0 ;i<res1.data.length;i++){
                    const temp1 = {
                        name: res1.data[i].name,
                        imgPosition: require('@/assets/birds/'+res1.data[i].img+''),
                        credit : res1.data[i].credit,
                        popup : res1.data[i].popup,
                        description: res1.data[i].description
                    }
                    this.birdsENG.push(temp1)
                }
                this.loading = false;
                
            } catch (error) {
                console.error('There was an error!', error);
            }
            if(this.mode === "english"){
                this.swapMode()
            }

        },
        changeShowModal(idx){
            if(!this.hasWindow){
                this.visible[idx] = true;
                this.hasWindow = true;
            }
        },
        deleteModal(idx){
            this.visible[idx] = false;
            this.hasWindow = false;
            console.log("我来了")
        },
        swapMode(){
            const temp = this.birds
            this.birds = this.birdsENG
            this.birdsENG = temp
        }
    },
    mounted(){
        this.get_data()
    },
    computed:{
        mode(){
            return this.$store.state.mode
        }
    },
    watch:{
        mode(){
            this.swapMode();
        }
    }
}
</script>


<style scoped>
#app1{
    padding: 0px;
    margin-left: 16%;
    width: 80%;
    
}
ul{
    text-align: center;
    margin: 0;
    overflow: scroll;
    list-style-type: none;
    padding: 0px;
    margin-bottom: 0px;
}
li{
    margin-top: 30px;
    text-decoration: none;
    margin-left: 15px;
    margin-right: 15px;
    float: left;
    padding: 0;
}
#windowIMG{
    width: 80%;
    height: auto;
    object-fit: contain;
    margin: 20px;
}
#popUp{
    height: 425px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
    margin-top: 10px;
}
#popUp p{
    margin: 10px;
    background-color: yellowgreen;
    color: white;
    padding: 20px;
    border-radius: 5px;
    height: 380px;
    width: 300px;
    float: right;
    overflow: scroll;
}
h2{
    margin: 0;
    padding: 15px;
    background-color:honeydew;
}
#right-bar{
    text-align: left;
}
</style>
