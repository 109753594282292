<template>
    <museum-page :idx="0"></museum-page>

</template>

<script>
import museumPage from '../museumPage.vue'

export default{
    components:{
        museumPage
    },
}
</script>