import { createApp } from 'vue'
import { createStore } from 'vuex'
import App from './App.vue'
import { createWebHashHistory, createRouter } from 'vue-router'
import illustratedPage from './components/illustratedPage.vue'
import museumInstance1 from './components/museums/museumInstance1.vue'
import museumInstance2 from './components/museums/museumInstance2.vue'
import mapPage from './components/mapPage.vue'
import GuessTheBird from './components/GuessTheBird.vue'
import tst from './components/upTest.vue'


const routes=[
    {path: '/', component: illustratedPage},
    {path: '/museum1', component: museumInstance1},
    {path: '/museum2',component:museumInstance2},
    {path: '/map',component:mapPage},
    {path: '/upload',component: tst},
    {path: '/gtb', component: GuessTheBird},
]

const router=createRouter({
    history: createWebHashHistory(),
    routes
})

// Code for vuex
const store = createStore({
    state(){
        return{
            mode: "chinese"
        }
    },
    mutations:{
        changeMode(state){
            if(state.mode === "chinese"){
                state.mode = "english"
            }else{
                state.mode = "chinese"
            }
        }
    }
})

createApp(App).use(router).use(store).mount('#app')
