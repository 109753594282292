<template>
    <div>
        <iframe :src=getSource frameborder="0"></iframe>
    </div>
</template>

<script>
export default{
    computed:{
        mode(){
            return this.$store.state.mode
        },
        getSource(){
            if (this.mode === "chinese"){
                return "https://api.symbiosisbirdweb.com/"
            }else{
                return "https://api.symbiosisbirdweb.com/ENG"
            }
        }
    },
}

</script>
<style scoped>
iframe{
    width: 71vw;
    height: 45vw;
    margin-left: 16vw;
}
div{
    height: 90vh;
}
</style>