<template>
    <div id="app">
        <div id="left">
            <h2>{{ mode==="chinese" ? "看图猜鸟":"Guess The Bird"}}</h2>
            <img id="img1" src="../assets/now.png" alt="">
        </div>

        <div id="right">
            <h2>{{ mode==="chinese" ? "上期答案":"Previous Answer"}}: <strong></strong></h2>
            <img id="img2" src="../assets/previous.png" alt="">
        </div>
    </div>
</template>


<script>
export default{
    watch:{
        mode(){
            return this.$store.state.mode
        }
    }
}
</script>

<style scoped>
#app{
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-left: 10%;
}
h2{
    text-align: center;
    font-size: 2vw;
}
#left{
    width: 35vw;
}
#img1{
    width: 100%;
}
#right{
    width: 35vw
}
#img2{
    width: 100%;
}
img{
    margin-top: 2vw;
    border-radius: 4%;
    border-style: solid;
    border-color:beige;
}
</style>


